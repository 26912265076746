$(document).ready(function(){

    $(".p_det_pack_slider").slick({
        infinite: false,
        arrows: false,
        dots: false,
        fade:true,
        autoplay: false,
        speed:500,
        autoplaySpeed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnFocus:false,
        pauseOnHover:false,
        asNavFor: '.p_det_thumb_slider',
        responsive: [
            {
              breakpoint: 1100,
              settings: {
					
              }
            }
        ]
    });

    $(".p_det_thumb_slider").slick({
        infinite: true,
        arrows: true,
        dots: false,
        fade:false,
        autoplay: true,
        speed:500,
        autoplaySpeed: 3000,
        slidesToShow: 3,
        slidesToScroll: 1,
        pauseOnFocus:false,
        pauseOnHover:false,
        focusOnSelect: true,
        asNavFor: '.p_det_pack_slider',
        responsive: [
            {
              breakpoint: 360,
              settings: {
				slidesToShow: 2,	
              }
            }
        ]
    });

    /* var tabData = $('.tabing_hld').find('.tab_data');
    var tabList = $('.tabing_con').find('li span');
    $(tabData[1]).css({display:'block'});
    $(tabList).each(function(){
        $(this).on('click', function(){
            var curTab ='#' + $(this).attr('tab');
            $(tabData).css({display:'none'});
            $(curTab).fadeIn(100);
            $(tabList).removeClass('active');
            $(this).addClass('active');
        });
    }); */
    

    var demoTrigger = $('.demo-trigger');
    demoTrigger.each(function(){
        var ele = $(this);
        new Drift(this, {
            paneContainer: $('.p_det_rt')[0],
            inlinePane: 900,
            inlineOffsetY: 0
        });
    })

});