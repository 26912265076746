var isMobile = new Object();
isMobile.Android = function () {
	return navigator.userAgent.match(/Android/i);
};

isMobile.BlackBerry = function () {
	return navigator.userAgent.match(/BlackBerry/i);
};
isMobile.iOS = function () {
	return navigator.userAgent.match(/iPhone|iPad|iPod/i);
};

isMobile.iPad = function () {
	return navigator.userAgent.match(/iPad/i);
};
isMobile.Opera = function () {
	return navigator.userAgent.match(/Opera Mini/i);
};
isMobile.Windows = function () {
	return navigator.userAgent.match(/IEMobile/i);
};

isMobile.Firefox = function () {
	return navigator.userAgent.match(/Firefox/gi);
};
isMobile.InternetExplorer = function () {
	return navigator.userAgent.match(/MSIE/gi);
};
isMobile.Opera = function () {
	return navigator.userAgent.match(/Opera/gi);
};
isMobile.Safari = function () {
	return navigator.userAgent.match(/Safari/gi);
};
isMobile.any = function () {
	return (
		isMobile.Android() ||
		isMobile.BlackBerry() ||
		isMobile.iOS() ||
		isMobile.Opera() ||
		isMobile.Windows()
	);
};
if (isMobile.any() && isMobile.iPad() == null) {
	//return;
}

var ua = window.navigator.userAgent;
var is_ie = /MSIE|Trident/.test(ua);

/*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

var winWT = $(window).innerWidth();
var winHT = $(window).innerHeight();

var disableScrolling = function(){
	var x=window.scrollX;
	var y=window.scrollY;
	window.onscroll=function(){window.scrollTo(x, y);};
}
var enableScrolling = function(){
	window.onscroll=function(){};
}
window.onbeforeunload = function () {
	window.scrollTo(0, 0);
}

$(document).ready(function(){

	$('.useApp .close').bind('click', function(){
		$('.useApp').css({'display':'none'});
		$('header').css({'position':'fixed'});
	});

	function stickyNav(){
		if ($(window).scrollTop() >= 100) {
			$('header').addClass('sticky');
		}
		else {
			$('header').removeClass('sticky');
		}
	}
	
	var useAppHT = $('.useApp').innerHeight();

	$(window).scroll(function(){
		useAppHT = $('.useApp').innerHeight();
		
		if ($(window).scrollTop() > useAppHT) {
			$('header').css({'position':'fixed'});
			$('.side_menu').css({'position':'fixed'});
		}else{
			if($('.useApp').css('display') == 'block'){
				$('header').css({'position':'absolute'});
				$('.side_menu').css({'position':'absolute'});
			}else{
				$('header').css({'position':'fixed'});
				$('.side_menu').css({'position':'fixed'});
			}
		}
		stickyNav();
	});
	
	$(".slimmenu").slimmenu({
		resizeWidth: '1023',
		collapserTitle: '',
		animSpeed: '500',
		easingEffect: null,
		indentChildren: true,
		childrenIndenter: '&nbsp;'
	});

	var navWT = $(".side_menu").innerWidth();
	$(".nav-icon").on('click',function(){
		//return;
		if($(this).hasClass("open")){
			$(".nav-icon").removeClass("open");
			$('.side_menu').animate({ right: -navWT + "px" }, 300);
			$('.menu_overlay').css({ display: 'none' });
			$('body').css({overflowY:'scroll'}); 
		}else{
			$(this).addClass("open");
			$('.side_menu').animate({ right: 0 }, 300);
			$('.menu_overlay').css({ display: 'block' });
			$('body').css({overflow:'hidden'});
		}
	});

	$(".menu_overlay").click(function () {
		$(".nav-icon").trigger("click");
	});

	var subLinks = $('nav a');
    $(subLinks).each(function(){
		$(this).click(function(){
            if($(this).attr('scrollTo')){
                var scrolPos = $(this).attr('scrollTo');
				console.log(scrolPos);
				if(scrolPos == null){
					return;
				}
                $("html, body").animate({ scrollTop:($(scrolPos).offset().top - 50) }, {duration:1200});
            }
        })
	});

	if(winWT <= 1023){
		subLinks.bind('click', function(){
			$(".nav-icon").trigger("click");
		});
	}

	$('.scrollTop').bind('click', function(e) {
		e.preventDefault();
		$('html, body').animate({scrollTop:0}, '500');
	});

	$('.leftNav a').each(function(){
		$(this).click(function(){
            if($(this).attr('scrollTo')){
                var scrolPos = $(this).attr('scrollTo');
                $("html, body").animate({ scrollTop:($(scrolPos).offset().top - 50) }, {duration:1200});
            }
        })
	});


	var socialCon = $('.quick_links').find('#socialCon');
	function setFooterSocialIcon(){
		winWT = $(window).innerWidth();
		//console.log(winWT);
		if(winWT <= 1279){
			var tempMob = socialCon.remove();
			$(tempMob).insertAfter('footer .quick_links');
		}else{
			var tempDesk = socialCon.remove();
			$(tempDesk).insertAfter('footer .quick_links .sine_up');
		}
	}
	setFooterSocialIcon();

	/* SEARCH POPUP */
	$('.search_btn').on('click', function(){
		$('#search_popup').css({display:'block'});
		$('body').css({'overflow-y':'hidden'});
		fleXenv.initByClass('flexcroll');
	});
	/* SEARCH POPUP */

	/* CART POPUP */
	var cartPopup = $("#cart_popup");
	var cartPopWT = cartPopup.innerWidth();

	function openCartPopup(){
		if($(this).hasClass("open")){
			cartPopup.animate({ right: -(cartPopWT + 50) + "px" }, 300);
			$(".cart_btn").removeClass("open");
			$('body').css({overflowY:'scroll'}); 
			$(".overlay_cart").css({display:'none'});
		}else{
			$(".overlay_cart").css({display:'block'});
			$(".cart_btn").addClass("open");
			cartPopup.animate({ right: 0 }, 300);
			$('body').css({overflow:'hidden'});
		}
	}
	window.openCartPopup = openCartPopup;

	$(".cart_btn").on('click',function(){
		openCartPopup();
	});

	$('#cart_popup .overlay_cart').click(function () {
		//$(".cart_btn").trigger("click");
		closeCartPopup();
	});

	$('#cart_popup h3 img').on('click', function(){
		closeCartPopup();
	});

	function closeCartPopup(){
		cartPopWT = cartPopup.innerWidth();
		$("#cart_popup").animate({ right: -(cartPopWT + 50) + "px" }, 300);
		$(".cart_btn").removeClass("open");
		$('body').css({overflowY:'scroll'}); 
		$(".overlay_cart").css({display:'none'});
	}
	window.closeCartPopup = closeCartPopup;
    /* CART POPUP */


	function closePopup(){
		$('.pop').css({display:'none'});
		$('body').css({'overflow-y':'scroll'});
	}
	$('.pop .close').bind('click', function(){
		closePopup();
	});
	$('.pop .overlay').bind('click', function(){
		closePopup();
	});


	function getMobileOperatingSystem() {
		var userAgent = navigator.userAgent || navigator.vendor || window.opera;
		// Windows Phone must come first because its UA also contains "Android"
		if (/windows phone/i.test(userAgent)) {
			return "Windows Phone";
		}
		if (/android/i.test(userAgent)) {
			return "Android";
		}
		// iOS detection from: http://stackoverflow.com/a/9039885/177710
		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			return "iOS";
		}
		return "unknown";
	}

	function DetectAndServe(){
		let os = getMobileOperatingSystem();
		if (os == "Android") {
			window.location.href = "https://play.google.com/store/apps/details?id=com.rcub.rclub&hl=en_IN&gl=US"; 
		} else if (os == "iOS") {
			window.location.href = "https://apps.apple.com/in/app/r-club/id938734610";
		} /* else if (os == "Windows Phone") {
			window.location.href = "http://www.WindowsPhoneexample.com";
		} else {
			window.location.href = "http://bcwebwise.com/";
		} */
	}

	$('.au_hld .button').bind('click', function(){
		DetectAndServe();
	});

	if(winWT < 1024){
		// Listen for orientation changes
		function onOrientationChange() {
			if (window.orientation == 90 || window.orientation == -90) {
				$(".rotate-msg").css("display", "block");
			} else {
				$(".rotate-msg").css("display", "none");
			}
		}
		window.addEventListener("orientationchange", onOrientationChange, false);
		onOrientationChange();
	}

	$(window).resize(function(){
		closeCartPopup();
		setFooterSocialIcon();
	});

	function isNumber(evt) {
		evt = (evt) ? evt : window.event;
		var charCode = (evt.which) ? evt.which : evt.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}

});