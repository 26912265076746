$(document).ready(function(){
    /*================================================================*/
    $('.p_com_slider .p_blurb').each(function(){
      var THIS = $(this);
      THIS.find('.inner_slider_hld').data().panelClone = THIS.find(".p_panel").clone();

    });

    $(".p_com_slider .product_slider").slick({
        infinite: true,
        arrows: true,
        dots: false,
        fade:false,
        autoplay: false,
        speed:500,
        autoplaySpeed: 3000,
        slidesToShow: 3,
        slidesToScroll: 1,
        draggable:false,
        pauseOnFocus:false,
        pauseOnHover:true,
        responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 1023,
              settings: {
                centerMode: true,
                centerPadding: '100px',
                slidesToShow: 1,
                //arrows: false,
              }
            },
            {
              breakpoint: 767,
              settings: {
                centerMode: true,
                centerPadding: '50px',
                slidesToShow: 1,
                //arrows: false,
              }
            },
            {
              breakpoint: 480,
              settings: {
                centerMode: true,
                centerPadding: '30px',
                slidesToShow: 1,
                //arrows: false,
              }
            }
        ]
      });

      function initProductInnerSlider(prop){
        var filter = 'all';
        if(prop && prop.filter){
          filter = prop.filter;
        }
        console.log(filter)
        $('.p_com_slider .p_blurb').each(function(index){
          var THIS = $(this);
          var tempPanelClone =  THIS.find('.inner_slider_hld').data().panelClone;
          function setUpInnerSlider(){
            THIS.find(".p_panel").remove();
            THIS.find('.inner_slider_hld').append(tempPanelClone.clone());
            var prodImgSlider = THIS.find(".p_panel");
            prodImgSlider.slick({
              infinite: true,
              arrows: false,
              dots: true,
              fade:false,
              autoplay: false,
              speed:400,
              autoplaySpeed: 1000,
              cssEase: 'linear',
              slidesToShow: 1,
              slidesToScroll: 1,
              draggable:false,
              pauseOnFocus:false,
              pauseOnHover:false,
              responsive: [
                  {
                    breakpoint: 1023,
                    settings:'unslick'
                  }
              ]
            });

            var currBullets = THIS.find('.slick-dots');
            var currBulletList = THIS.find('.slick-dots li');
            var isSliderPlay = false;
            THIS.mouseenter(function() {
              isSliderPlay = false;
              prodImgSlider.slick('play');
              if(currBulletList.length > 1){
                currBullets.css({opacity:1});
              }
            });
      
            THIS.mouseleave(function() {
              prodImgSlider.slick('pause');
              currBullets.css({opacity:0});
              isSliderPlay = true;
              prodImgSlider.slick('slickGoTo',0);
            });
    
            prodImgSlider.on('afterChange', function(event, slick, currentSlide, nextSlide){
              if(isSliderPlay == true){
                isSliderPlay = false;
                setTimeout(function(){
                  prodImgSlider.slick('slickGoTo',0);
                }, 50);
              }
            });
            prodImgSlider.slick('slickGoTo',0);
          }
          setUpInnerSlider();
        });
      }

      initProductInnerSlider();
      window.initProductInnerSlider = initProductInnerSlider;

      $(window).on('resize', function(){
        setTimeout(function(){
          initProductInnerSlider();
        }, 200);
      });
    /*================================================================*/
});