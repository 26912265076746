$(document).ready(function(){
  var player;
	$("#home_banner").slick({
        infinite: true,
        arrows: true,
        dots: true,
        fade:false,
        autoplay: true,
        speed:500,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnFocus:false,
        pauseOnHover:false,
        responsive: [
            {
              breakpoint: 768,
              settings: {
                adaptiveHeight: true
              }
            }
        ]
    });

    $('.popup-youtube').magnificPopup({
      type: 'iframe',
      fixedContentPos: true,
      closeOnBgClick: true,
      mainClass: 'mfp-fade',
      removalDelay: 160,
      preloader: false,
      fixedContentPos: true,
      iframe: {
        patterns: {
          youtube: {
            index: 'youtube.com',
            id: 'v=',
            src: 'https://www.youtube.com/embed/%id%?rel=0&autoplay=1'
          }
        }
      },
      callbacks: {
        open: function() {
          console.log('open');
          $("#home_banner").slick('slickPause');
        },
        close: function() {
          console.log('close');
          $("#home_banner").slick('slickPlay');
        }
      }
    });

    $(".tvc_slider").slick({
      infinite: true,
      arrows: true,
      dots: false,
      fade:false,
      autoplay: false,
      speed:500,
      autoplaySpeed: 3000,
      slidesToShow: 4,
      slidesToScroll: 1,
      pauseOnFocus:false,
      pauseOnHover:true,
      responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 1023,
            settings: {
              centerMode: true,
              centerPadding: '100px',
              slidesToShow: 2,
              arrows: false,
            }
          },
          {
            breakpoint: 767,
            settings: {
              centerMode: true,
              centerPadding: '20px',
              slidesToShow: 2,
              arrows: false,
            }
          },
          {
            breakpoint: 400,
            settings: {
              centerMode: true,
              centerPadding: '70px',
              slidesToShow: 1,
              arrows: false,
            }
          }
      ]
    });

    var videoThumb = $('.tvc_slider').find('.thumb_hld');
    videoThumb.each(function(){
      $(this).bind('click', function(){
        var currVidId= $(this).attr('videoURL');
        player.cueVideoById(currVidId);
        window.onPlayerUnMute();
        window.playYoutubeVideo();
        videoThumb.removeClass('curr_video_thumb');
        $(this).addClass('curr_video_thumb');
      });
    });

  

    setupYoutubePlayer = function (){
      //console.log('asdsadsad');
        var tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        
        // 3. This function creates an <iframe> (and YouTube player)
        //    after the API code downloads.
        
        window.playYoutubeVideo = function(){
            player.playVideo();
        }
        window.onYouTubeIframeAPIReady = function () {
            player = new YT.Player('player', {
            height: '390',
            width: '640',
            videoId: 'O82fO4mOB10',
            playerVars:{
                showinfo:0,
                rel:0,
                modestbranding:1,
                autohide:1,
                showinfo:0,
                color:"#ffff00"
            },
            events: {
                'onReady': onPlayerReady,
                'onStateChange': onPlayerStateChange
            }
            });
        }
    
        // 4. The API will call this function when the video player is ready.
        window.onPlayerReady =  function(event) {
            //event.target.playVideo();
            //window.playYoutubeVideo();
        }

        window.onPlayerMute=  function(event) {
          player.mute();
        }

        window.onPlayerUnMute =  function(event) {
          player.unMute();
        }
    
        // 5. The API calls this function when the player's state changes.
        //    The function indicates that when playing a video (state=1),
        //    the player should play for six seconds and then stop.
        var done = false;
        function onPlayerStateChange(event) {
            if (event.data == YT.PlayerState.PLAYING && !done) {
            /*setTimeout(stopVideo, 6000);
            done = true;*/
            }
        }
        window.stopYoutubeVideo = function () {
            player.stopVideo();
        }
        window.pauseYoutubeVideo = function () {
          player.pauseVideo();
      }
    }

    $.fn.isInViewport = function() {
        var elementTop = $(this).offset().top;
        var elementBottom = elementTop + $(this).outerHeight();
        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();
        return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    if ($('.tvc_hld').isInViewport()) {
      window.playYoutubeVideo();
    } else {
      //window.pauseYoutubeVideo();
    }

     var isPause = true;
     $(window).on('resize scroll', function() {
        if ($('.tvc_hld').isInViewport()) {
          if(isPause){
              window.playYoutubeVideo();
              window.onPlayerMute();
              isPause = false;
          }

        } else {
          //window.pauseYoutubeVideo();
        }
    });
});

$(window).on('load',function(){  
  setupYoutubePlayer(); 
})